<template>
  <div class="hotel-order"  v-show="isShow">
    <div class="head-hotel"  v-if=" hotel != null && hotel.hotelRoom != null && hotel.shopInfo != null ">
      <img src="../../assets/img/commoditySpike/back.png"  alt="" @click="$goBack()" />
        
      <div class="title">订单{{hotel.orderNo}}</div>
    </div>
 
    <Head :type="0"   v-if="hotel == null || hotel.hotelRoom == null || hotel.shopInfo == null"></Head>
   
    <div class="main" v-if=" hotel != null && hotel.hotelRoom != null &&hotel.shopInfo != null " >
        <div class="box">
          <div v-if="hotel.refundStatus==='0'">
      <div class="title" v-if="hotel.isPay==='0'&&str===1&&hotel.status!=='2'">支付超时</div>
      <div class="tip" v-if="hotel.isPay==='0'&&str===1&&hotel.status!=='2'">订单已超过可支付时间,请重新下单</div>
      <div class="title" v-if="hotel.isPay==='0'&&str!=1 &&hotel.status!='2'">待支付</div>
      <div class="tip" v-if="hotel.isPay==='0'&&str!=1 &&hotel.status!='2'">剩余时间: {{str}}</div>
       <div class="title" v-if="hotel.status==='2'">已取消</div>
      <div class="tip" v-if="hotel.status==='2'">订单已取消</div>
       <div class="title" v-if="hotel.status=='0'&& hotel.isPay==='1' ">待入住</div>
      <div class="tip" v-if="hotel.status=='0'&&hotel.isPay==='1' ">您已付款，请及时入住</div>
      <div class="title" v-if="hotel.status=='1'&& (hotel.appraisesStatus-0)">已完成</div>
      <div class="tip" v-if="hotel.status=='1'&&hotel.appraisesStatus-0">感谢您入住本酒店，期待下次光临</div>
      </div>
        <div class="title" v-if="hotel.refundStatus==='1'">退款中</div>
      <div class="tip"  v-if="hotel.refundStatus==='1'">正在退款中</div>
      <div class="title" v-if="hotel.refundStatus==='2'">已退款</div>
      <div class="tip"  v-if="hotel.refundStatus==='2'">退款已完成</div>
      <div class="title" v-if="hotel.refundStatus==='3'">退款失败</div>
      <div class="tip"  v-if="hotel.refundStatus==='3'">商家不同意退款</div>
       <div class="title" v-if="hotel.status==='1'&& !(hotel.appraisesStatus-0)">待评价</div>
      <div class="tip"   v-if="hotel.status==='1'&&  !(hotel.appraisesStatus-0)">期待您的评价</div>



<!-- ((hotel.isPay==='0'&&str===1) ||hotel.status==='2')||hotel.appraisesStatus-0 -->
      <div class="btns" v-if="hotel.status==='2'">
        <div @click="del()">删除订单</div> 
        <div @click="datail()">再次预定</div>
      </div>
      <div class="btns" v-if="hotel.appraisesStatus -0">
       
        <div @click="datail()">再次预定</div>
      </div>

      <div class="btns"  v-if="hotel.isPay==='0'&&str!=1  &&hotel.status!='2'">
        <!-- <p @click="del()">删除订单</p> -->
        <div @click="remove">取消订单</div>
        <div @click="show=true">去支付￥{{hotel.paymentPrice}}</div>
      </div>

       <div class="btns" v-if="hotel.status=='0'&& hotel.isPay==='1' && !hotel.refundId ">
        <div @click="refund(hotel.id)"  v-if=" Date.now() + 3600 *1000 - new Date(hotel.estimateTime.replace(/-/g,'/').slice(0,10 )+ ' 18:00:00').getTime()<0" >申请退款</div>
        <div @click="datail()">再次预定</div>
      </div>
        <div class="btns" v-if="hotel.refundStatus!=='0'">
           <!-- <p @click="del()">删除订单</p> -->
           <div @click="refundDetail">查看详情</div>
           <div @click="datail">再次预定</div>
      </div>
      <div class="btns" v-if="hotel.status=='1'&& !(hotel.appraisesStatus-0)">
        <div  @click="$router.push({ 
                  path:'evaluate',
                  query:{ 
                    id:hotel.id,
                    shopId:hotel.shopId,
                    type:4,
                    shopName:hotel.shopInfo.shopName
                  }
                })"  >去评价</div>
       
        <div @click="datail()">再次预定</div>
      </div>

      
      <div class="card">
          <div class="title">
             {{hotel.shopInfo.name}}
          </div>
          <div class="location">
              {{hotel.shopInfo.address}}
          </div>
          <div class="icons">
              <div  @click="roadNav(hotel.shopInfo.longitude,hotel.shopInfo.latitude,hotel.shopInfo.name)">
                  <img src="../../assets/img/Home.png" alt="">
                  地图/导航
              </div>
              <span></span>
              <div><a :href="'tel:'+hotel.shopInfo.phone">
                  <img src="../../assets/img/Home.png" alt="">
                 联系商家
                 </a>
              </div>
          </div>
      </div>
      {{data}}
      <div class="warp">
      <div class="time" v-if="hotel.hotelRoom.roomType==='0'">
          <div>{{filter(hotel.checkinDate.replace(/-/ig,'/'))}}</div>
          <div class="middle"> <div><p>{{hotel.duration}}晚</p></div> </div>
          <div>{{filter(hotel.checkoutDate.replace(/-/ig,'/'))}}</div>
      </div>
      <div class="times" v-if="hotel.hotelRoom.roomType==='0'">
          <div>15:00后</div>
          <div class="last">12:00前</div>
      </div>
       <div class="time" v-if="hotel.hotelRoom.roomType==='1'">
          <div>{{hotel.checkinDate.slice(5,10).replace('-',"月")}} 周{{ day[Number(new Date(hotel.checkinDate.replace(/-/ig,'/')).getDay()) ]}}</div>
      </div>
      <div class="room-type">
          {{hotel.hotelRoom.name}}
      </div>
      <div class="room-provice">
          {{hotel.hotelRoom.haveBreakfast==='0'?'无':'有'}}早餐<span v-if="hotel.hotelRoom.measure"> · {{hotel.hotelRoom.measure}}㎡</span>  <span v-if="hotel.hotelRoom.bedSize"> · {{hotel.hotelRoom.bedSize}}m床</span>  <span v-if="hotel.hotelRoom.occupancy">· {{hotel.hotelRoom.occupancy}}人入住</span> 
      </div>
     
      <ul>
          <li class="person-info">
              <div class="name">房间数量</div>
              <div class="content"><span>{{hotel.roomNum}}间（每间最多住{{hotel.hotelRoom.occupancy}}人）</span></div>
          </li>

          <li class="person-info" v-for=" item,index in hotel.hotelOrderItems" :key="index">
              <div class="name">住客姓名</div>
              <div class="content"><span>{{item.userName}}</span></div>
          </li>

           <li class="person-info">
              <div class="name">联系手机</div>
              <div class="content"><span>+86</span>{{hotel.phone}}</div>
          </li>

          <li class="person-info" v-if="hotel.hotelRoom.roomType==='0'">
              <div class="name">预计到店</div>
              <div class="content"><span>{{ hotel.checkinDate.slice(0,10)== hotel.estimateTime.slice(0,10)?hotel.estimateTime.slice(5,10).replace('-',"月")+"日 ":hotel.estimateTime.slice(5,10).replace('-',"月")+"日 次日"}}{{hotel.estimateTime.split(' ')[1].slice(0,5)}}前</span></div>
          
          </li>
          <li class="person-info" v-if="hotel.hotelRoom.roomType==='1'">
              <div class="name">入住时段</div>
              <div class="content"><span> {{hotel.checkinDate.slice(11,13)+':00'}} ~ {{ Number(hotel.checkinDate.slice(11,13))+ hotel.hotelRoom.roomDuration+':00'}}</span></div>
          </li>
      </ul>
</div>
<div class="warp">
      <ul>
         <div class="infomation">订单信息</div>
        <li class="person-info">
              <div class="name " >订单号</div>
              <div class="content"><span class="copy" :data-clipboard-text="hotel.orderNo" @click="copy()">{{hotel.orderNo}}</span></div>
          </li>
          <li class="person-info">
              <div class="name">下单时间</div>
              <div class="content"><span>{{hotel.createTime}}</span></div>
          </li>

          
      </ul>
      </div>
    </div>
    </div>
     <van-action-sheet v-model="show" title="标题">
          <div class="pay-box">
            <van-radio-group v-model="payType">
        <van-cell-group>
          <van-cell clickable @click="payType = '1'">
            <template #title>
              <img class="img" src="../../assets/img/weChat-icon.png" alt />
              <span class="custom-title">微信</span>
            </template>
            <template #right-icon>
              <van-radio name="1" />
            </template>
          </van-cell>
          <span class="line"></span>
          <van-cell clickable @click="payType = '2'">
            <template #title>
              <img class="img" src="../../assets/img/alipay-icon.png" alt />
              <span class="custom-title">支付宝</span>
            </template>
            <template #right-icon>
              <van-radio name="2" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
      <div class="pay" @click="pay()">支付</div>
          </div>
        </van-action-sheet>
  </div>
</template>

<script>
import {hotelPay} from '../../common/hotelPay'
import { hotelDel }from '../../api/hotel/index'
import {hotel} from '../../api/hotelOrder/index'
import {cancelHotel} from '../../api/cancelOrder/index'
import  Head  from '../../components/head/index.vue'
export default {
  data(){
    return{
      isData:false,
      hotel:null,
      timerId:0,
      str:'',
      show:false,
      payType:'1',
      day:['日',"一","二","三","四","五","六"],
      data:'',
      isShow: false
    }
  },
  components:{ 
    Head
  },
  methods: {
    copy(){ 

                
                 let clipboard = new this.Clipboard('.copy');
                    clipboard.on("success", () => {
                        this.$toast("复制成功");
                        // 释放内存
                        clipboard.destroy();
                    });
                    clipboard.on("error", () => {
                        // 不支持复制
                        this.$toast("该浏览器不支持自动复制");
                        // 释放内存
                        clipboard.destroy();
                    });
                
            
        },
    
     remove(){
                cancelHotel(this.hotel.id).then(res=>{
                    if(res.data.code===0){
                        this.$toast.success('取消成功')
                        this.hotel.status='2'
                        this.$set(this.hotel,'status','2')
                        sessionStorage.setItem('status',JSON.stringify({status:2,attribute:'hotelStatus',index:this.$route.query.index+''}))
     
                        clearInterval(this.timerId)
                    }
                })
            },
    filter(data){
     let timer =data.split('/')[1]+'月' +data.split('/')[2].split(' ')[0]+'日'
     data.split('/')
      let arr=["日",'一','二',"三",'四',"五",'六']
     return timer+' 周'+arr[new Date(data).getDay()]
   },
   getTi(data){
     clearInterval(this.timerId)
        let dataStr1
    
      console.log(data,111122);
    let  dataStr= String(data).replace(/-/ig,'/')
    if(Date.now()-new Date(dataStr).getTime()>15*60*1000){
      this.str = 1
    }else{
      // dataStr1 =  Date.now()-new Date(dataStr).getTime()
       dataStr1 =15*60*1000-(new Date().getTime()-new Date(dataStr).getTime())
      let min = parseInt(dataStr1/60000)
          if(min>9){
            min = min+'分'
           }else{
                if(min==0){
                  min=''
                  }else{
                    min = '0'+min+'分'
                  }
          }
          
          let sec =parseInt((dataStr1 %60000)/1000)
           if(sec>9){
            sec = sec+'秒'
          }else{
                sec = '0'+sec+'秒'
          }
          this.str =min+sec
     this.timerId=setInterval(() => {
         if(Date.now()-new Date(dataStr).getTime()+15*60*1000<0){
         this.str = 1
         clearInterval(this.timerId)
         }
          dataStr1 =15*60*1000-(new Date().getTime()-new Date(dataStr).getTime())
          let min = parseInt(dataStr1/60000)
          if(min>9){
            min = min+'分'
           }else{
                if(min==0){
                  min=''
                  }else{
                    min = '0'+min+'分'
                  }
          }
          
          let sec =parseInt((dataStr1 %60000)/1000)
           if(sec>9){
            sec = sec+'秒'
          }else{
                sec = '0'+sec+'秒'
          }
          this.str =min+sec
      }, 1000);
    }

   },
   refund(id){
     this.$router.push({
       path:'refund',
       query:{
         orderId:id,
         orderItemId:this.hotel.orderNo,
         orderType:'1',

       }
     })
   },
   refundDetail(){
     this.$router.push({
       path:'refundDetail',
       query:{
         id:this.hotel.refundId,
         refundId: this.hotel.refundStatus==2 ? 3 :this.hotel.refundStatus==3? 2:this.hotel.refundStatus==3 ,
         type:1
       }
     })
   },
   pay(){
     window.closePage=this.closePage
      hotelPay(this.hotel.id,this.payType,this.hotel.paymentPrice )
   },
   closePage(data){
    if(data==1){
      return
    }
     this.hotel.isPay='1'
     sessionStorage.setItem('isPay',JSON.stringify({pay:'1',index:this.$route.query.index}))

   },
   del(){
     hotelDel(this.hotel.orderNo).then(res=>{
       if(res.data.code===0){
         this.$toast.success('删除成功')
         localStorage.setItem('index',this.$route.query.index+'')
         setTimeout(()=>{
           this.$router.back()
         },1000)
       }else{
         this.$toast.fail('删除失败')
       }
     })
   },
   datail(){
    
     
     if(this.hotel.hotelRoom.roomType==='0'){
       this.$router.push({
        path:'/AhotelDetails',
        query:{
         start: parseInt(Date.now()/1000) ,
         end:parseInt((Date.now()+24*60*60*1000)/1000),
         session:this.$store.state.token,
         roomNum:1,
         personNum:1,
         kidsNum:0,
         latitude:'31.772639',
         longitude:'117.227962',
         type:'0',
         id:this.hotel.shopId
       }
     })
     }else{
       this.$router.push({
       path:'/AhotelDetails',
       query:{
         start: parseInt(Date.now()/1000) ,
         end:parseInt((Date.now()+24*60*60*1000)/1000),
         session:this.$store.state.token,
         roomNum:1,
         personNum:1,
         kidsNum:0,
         latitude:'31.772639',
         longitude:'117.227962',
         type:'1',
         id:this.hotel.shopId
       }
     })

     }
     
   },
   
  },
  created(){
    try{ 
      window.webkit.messageHandlers.statusBar.postMessage(JSON.stringify({color:'#f6f6f6'}))
    }catch{ 
      //
    }
  
  hotel(this.$route.query.id).then(res=>{
  if(res.data.code===0){
    
    
  this.hotel=res.data.data
  if( this.hotel && this.hotel.hotelRoom ){ 
    try{ 
       window.webkit.messageHandlers.statusBar.postMessage(JSON.stringify({img:'http://121.36.73.162:9000/joolun/1/material/c44973ad-ea8b-4dbd-9bfc-8f82d7c9a45d.png'}))
    }catch{ 
      //
    }
 

  }
  this.getTi(this.hotel.createTime)
  }
  this.isShow = true
})


//  try{ 
//       window.webkit.messageHandlers.statusBar.postMessage(JSON.stringify({img:'http://121.36.73.162:9000/joolun/1/material/c44973ad-ea8b-4dbd-9bfc-8f82d7c9a45d.png'}))

//         }catch{ 
//           //
//         }


  },
  mounted() {
  
     
    this.setRem();
    window.addEventListener("orientationchange", this.setRem);
    window.addEventListener("resize", this.setRem);
  },
  beforeDestroy(){
    try{ 
     window.webkit.messageHandlers.statusBar.postMessage(JSON.stringify({}))
    }catch{ 
      //
    }

    clearInterval(this.timerId)
  }
}
</script>

<style lang="less" scoped>
 .pay-box {
    padding: .213333rem .213333rem 0;
    height: 6.666667rem;
    img{
      width: .666667rem;
      height: .666667rem;
      margin-right: .266667rem;
     
    }
  /deep/  .van-cell__title{
      display: flex;
      align-content: center;
    }
    .pay{
      width: 8.24rem;
  height: 0.96rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 0.4rem;
  font-weight: 500;
  background-color: #e64d42;
  margin: 1.533333rem auto 0.266667rem;
  border-radius: 0.56rem;
    }
  }
.hotel-order {
  position:fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: PingFangSC-Medium, PingFang SC;
  background: url(../../assets/img/loseIt.png) no-repeat center ;
  background-color: #f6f6f6;
 
  .head-hotel {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.24rem 0.933333rem 0.24rem .133333rem;
    box-sizing: border-box;
    // background-image: linear-gradient(to right, #ff7200, #ff3c00);
    background-image: url("../../assets/img/hotel_bottom.png");
    background-repeat: no-repeat;
    background-size: 100% ;
    

    img {
      width: 0.666667rem;
      height: 0.64rem;
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      color: white;
      font-size: .453333rem;
    }
  }
  .main::-webkit-scrollbar{ 
    display: none;
  }
    .main {
    
    flex: 1;
    box-sizing: border-box;
    overflow: auto;
    background-color: #f1f1f1;
     background-image: url("../../assets/img/hotel_bottom.png");
    background-repeat: no-repeat;
    background-size: 100%  4.6rem;
    width: 100%;
    .box{
          background-repeat: no-repeat;
          padding:  0.66rem 0.266667rem .266667rem;
          box-sizing: border-box;
          padding-bottom:constant(safe-area-inset-bottom); //兼容IOS < 11.2
          padding-bottom:env(safe-area-inset-bottom);//兼容IOS > 11.2
          margin-bottom: 1rem;
    }
    .title {
      font-size: 0.56rem;
      color: white;
      padding-left: 0.266667rem;
      margin-bottom: 0.266667rem;




    }
    .tip {
      color: white;
      padding-left: 0.266667rem;
      font-size: 0.373333rem;
      margin-bottom: 0.16rem;
    }
    .btns {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #ff5200;
      font-size: 0.373333rem;
      margin-bottom: 0.4rem;
      div {
        width: 4.586667rem;
        height: 1.173333rem;
        background-color: white;
        border-radius: 0.266667rem;
        display:flex;
        justify-content: center;
        align-items:center;
      }
      p{
        width:30%;
        height: 1.066667rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius:.133333rem;
       
      }
    }
    .card{
        padding: .266667rem;
        background-color: white;
        border-radius: .266667rem;
        .title{
            font-size: .48rem;
            margin-bottom: .133333rem;
            color: black;
        }
        .location{
            font-size: .32rem;
            color: #999999;
            margin-bottom: .533333rem;
        }
        .icons{
            display: flex;
            align-items: center;
            justify-content: space-between  ;
            margin-bottom: .16rem;
            div{
                flex: 1;
                justify-content: center;
                color: #333;
                display: flex;
                font-size: .373333rem;
                img{
                    width: .533333rem;
                    height: .533333rem;
                    margin-right: .266667rem;
                }
                a{
                   flex: 1;
                justify-content: center;
                color: #333;
                display: flex;
                font-size: .373333rem;
                }
                
            }
            span{
                display: block;
                width: .026667rem;
                height: .96rem;
                background-color: #E5E5E5;
            }
        }
        
    }
    .time{
            display: flex;
            align-items: center;
            padding-left: .266667rem;
            margin-bottom: .16rem;
            div{
                font-size: .426667rem;
                span{
                    margin-left: .08rem;
                }
            }
            img{
                width: 1.2rem;
                height: .4rem;
                margin: 0 .266667rem;
            }
        }
        .times{
            margin-bottom: .56rem;
            display: flex;
            margin-left: .266667rem;
            font-size: .32rem;
            color: #999;
            div{
               flex: 1;
                
            }
            .last{
              text-align: right;
              padding-right: 2.5rem;
            }
        }
    .room-type{
        font-size: .4rem;
        padding-left: .266667rem;
        margin-bottom: .133333rem;
    }
    .room-provice{
        font-size: .32rem;
        color: #999;
        margin-bottom: .266667rem;
        padding-left: .266667rem;
    }
    ul{
        padding-left: .266667rem;
        .person-info{
            display: flex;
            height: 1.493333rem;
            align-items: center;
            font-size: .453333rem;
            color: #999;
           .content{
               margin-left: .56rem;
               color: #333;
               span{
                   margin-right: .4rem;
               }
           }
        }
        .infomation{
            color: #333;
            font-size: .453333rem;
            margin: .56rem 0 .266667rem;
            font-weight: 600;
        }
    }
  }
}
.warp{
  background-color: white;
  margin-top: .266667rem;
  padding: .266667rem;
  box-sizing: border-box;
  border-radius: .266667rem;
   
}

.middle{
  background-color: #ff5200;
  position: relative;
  height: 2px;
  width: 1.6rem;
  margin: 0 .266667rem;
  border-radius: 50%;
  p{
    position: absolute;
    top: -0.2rem;
   
    left: 0;
    border-radius: .266667rem;
    border: 1px solid #ff5200;
    padding: 0 .266667rem;
    white-space: nowrap;
    height: .4rem;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 50%;
    transform: translateX(-50%);
    color:#ff5200 ;
    font-size: .32rem;
  }
}
.lose{ 
  width: 50%;
  margin-top: 40%;
  margin-left: 50%;
  transform: translateX(-50%);
}
</style>