import { aliPay, wxPay } from "../api/hotel/index"
import { openPay} from './index'
import { Toast} from 'vant'
function submitAlipay(form,type) {
            aliPay(form).then((res) => {
            if(res.data.code != 0){ 
              Toast(res.data.msg)
              return
            }
            openPay(res,type)
        })
}

  //微信支付
  function getWxPay(data,type) {
    wxPay({ orderId: data, tradeType: "APP" }).then((res) => {
      if(res.data.code != 0){ 
        Toast(res.data.msg)
        return
      }
        openPay(res,type)
    })
  }


 function hotelPay(id,type,price){
 
        if(type==='1'){
          getWxPay(id,type)

        }else{
            let data={
                outTradeNo:id,
                pamentPrice: price
            }
            submitAlipay(data,type)
        }
  }

  export{
    hotelPay}